import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AuthWrapper, Image } from "react-web-skeleton";
import image from "../../../../assets/img/login/sideimage.png";

const AuthTemplate = () => {
  return (
    <>
      <AuthWrapper type={2}>
        <div className="flex flex-row justify-center my-auto px-[16px] md:pr-0 md:pl-[48px]">
          <div className="form-content card flex flex-col gap-[48px] md:gap-[32px] w-full md:w-8/12 lg:w-4/12">
            <Suspense fallback="loading...">
              <Outlet />
            </Suspense>
          </div>
        </div>
      </AuthWrapper>
    </>
  );
};

export default AuthTemplate;
