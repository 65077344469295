import React from "react";
import { checkAuthentication, checkRoleId } from "utils/authUtils";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const isAuthenticated = checkAuthentication();
  const roleId = checkRoleId();

  console.log(allowedRoles)
  console.log(roleId)

  const isAuthorized = isAuthenticated && allowedRoles.includes(roleId);

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
