import { lazy } from "react";
import { Navigate, Route, Routes as Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import RootTemplate from "components/template";
import AuthTemplate from "components/page/Auth/Layout";

// s.Page Error
import NotFound from "components/page/ErrorPage/404";
import AddTemplate from "components/page/Catalog/Layout";
// e.Page Error

// s.Auth
const Login = lazy(() => import("components/page/Auth/Login/index"));
const ForgotPassword = lazy(() =>
  import("components/page/Auth/ForgotPassword")
);
const SuccessResetPassword = lazy(() =>
  import("components/page/Auth/ForgotPassword/SuccessSendResetPassword")
);
const NewPassword = lazy(() =>
  import("components/page/Auth/ForgotPassword/NewPassword")
);
// e.Auth

// s.Admin
const Dashboard = lazy(() => import("components/page/Dashboard"));
const MasterBrand = lazy(() => import("components/page/Master/Brand"));
const MasterModel = lazy(() => import("components/page/Master/Model"));
const MasterType = lazy(() => import("components/page/Master/Type"));
const MasterTransmission = lazy(() =>
  import("components/page/Master/Transmission")
);
const MasterFuel = lazy(() => import("components/page/Master/Fuel"));
const MasterGrade = lazy(() => import("components/page/Master/Grade"));
const MasterCategory = lazy(() => import("components/page/Master/Category"));
const MasterColor = lazy(() => import("components/page/Master/Color"));
const Admin = lazy(() => import("components/page/Admin"));
const Catalog = lazy(() => import("components/page/Catalog"));
const AddOrUpdateCatalog = lazy(() =>
  import("components/page/Catalog/AddOrUpdateCatalog")
);
const DetailCatalog = lazy(() =>
  import("components/page/Catalog/DetailCatalog")
);
const UserInvestor = lazy(() => import("components/page/User/Investor"));
const UserDetailInvestor = lazy(() =>
  import("components/page/User/Investor/DetailInvestor")
);
const WalletTopup = lazy(() => import("components/page/Wallet/Topup"));
const WalletWithdraw = lazy(() => import("components/page/Wallet/Withdraw"));
const WalletDetailTopup = lazy(() =>
  import("components/page/Wallet/DetailTopup")
);
const WalletDetailWithdraw = lazy(() =>
  import("components/page/Wallet/DetailWithdraw")
);

const FundingPayment = lazy(() => import("components/page/Funding/Payment"));
const FundingDetailPayment = lazy(() =>
  import("components/page/Funding/DetailPayment")
);

const FundingProfitSharing = lazy(() =>
  import("components/page/Funding/ProfitSharing")
);
const FundingDetailProfitSharing = lazy(() =>
  import("components/page/Funding/DetailProfitSharing")
);
const Configuration = lazy(() =>
  import("components/page/Configuration")
);
// e.Admin

export default function Routes() {
  return (
    <Switch>
      <Route element={<AuthTemplate />}>
        <Route element={<Navigate replace to="dashboard" />} index />
        <Route element={<Login />} path="login" index />
        <Route element={<ForgotPassword />} path="forgot-password" />
        <Route element={<NewPassword />} path="verification-password/:param" />
        <Route
          element={<SuccessResetPassword />}
          path="success-change-password"
        />
        {/* <Route element={<NewPassword />} path="new-password" /> */}
      </Route>

      <Route element={<RootTemplate />}>
        <Route element={<Navigate replace to="dashboard" />} index />
        {/* s.Admin Route */}

        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["Superadmin"]} />}
        >
          <Route element={<MasterBrand />} path="master/merek" />
          <Route element={<MasterModel />} path="master/model" />
          <Route element={<MasterType />} path="master/tipe" />
          <Route element={<MasterTransmission />} path="master/transmisi" />
          <Route element={<MasterFuel />} path="master/bahan-bakar" />
          <Route element={<MasterGrade />} path="master/grade" />
          <Route element={<MasterCategory />} path="master/kategori" />
          <Route element={<MasterColor />} path="master/warna" />
          <Route element={<Admin />} path="admin" />
          <Route element={<Configuration />} path="konfigurasi" />
        </Route>

        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["Superadmin", "Admin"]} />}
        >
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<Catalog />} path="katalog" />
          <Route element={<DetailCatalog />} path="katalog/detail/:id" />
          <Route element={<UserInvestor />} path="user/investor" />
          <Route
            element={<UserDetailInvestor />}
            path="user/investor/detail/:id"
          />
          <Route element={<WalletTopup />} path="wallet/top-up" />
          <Route
            element={<WalletDetailTopup />}
            path="wallet/top-up/detail/:id"
          />
          <Route element={<WalletWithdraw />} path="wallet/withdraw" />
          <Route
            element={<WalletDetailWithdraw />}
            path="wallet/withdraw/detail/:id"
          />
          <Route element={<FundingPayment />} path="pendanaan/pembelian" />
          <Route
            element={<FundingDetailPayment />}
            path="pendanaan/pembelian/detail/:id"
          />
          <Route
            element={<FundingProfitSharing />}
            path="pendanaan/bagi-hasil"
          />
          <Route
            element={<FundingDetailProfitSharing />}
            path="pendanaan/bagi-hasil/detail/:id"
          />
        </Route>
        {/* e.Admin Route */}

        {/* s.Error Page  */}
        <Route path="*" element={<NotFound />} />
        {/* e.Error Page  */}
      </Route>
      <Route element={<AddTemplate />}>
        <Route element={<Navigate replace to="login" />} index />
        {/* s.Admin Route */}

        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["Superadmin", "Admin"]} />}
        >
          <Route element={<AddOrUpdateCatalog />} path="katalog/add" />
        </Route>
      </Route>
    </Switch>
  );
}
