import { Axios } from "helper/Axios";
import { clearLocalStorageData } from "utils/authUtils";

export const getNotifications = async (filter) => {
  try {
    const URL = "notification";
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      clearLocalStorageData();
      window.location.href = "/login";
      return;
    }
  }
};

export const readNotifications = async (id) => {
  try {
    const URL = `notification/read`;
    const response = await Axios.post(URL);
    return response.data.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      clearLocalStorageData();
      window.location.href = "/login";
      return;
    }
  }
};
