import React from "react";
import { Loading } from "react-web-skeleton";

export const LoadingScreen = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center text-white text-24">
      <Loading size="xl" />
    </div>
  );
};

export const LoadingPage = () => {
  return (
    <div className="mt-40 mr-40 flex items-center justify-center  bg-opacity-50 ">
      <Loading size="xl" />
    </div>
  );
};
