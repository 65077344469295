import React from "react";
import { Sidebar as Side } from "react-web-skeleton";

import logo from "assets/img/logo/logo.png";
import logoRounded from "assets/img/logo/logo-rounded.png";

const Sidebar = (props) => {
  let urlLogo = props.isExpanded ? logo : logoRounded;

  return (
    // <StoryRouter routePath="*">
    <React.Fragment key=".0">
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Side
          className=""
          handleExpandedSidebar={props.handleExpandedSidebar}
          logo={urlLogo}
          isExpanded={props.isExpanded}
          routes={props.routes[0]}
        />
      </div>
    </React.Fragment>
    // </StoryRouter>
  );
};

export default Sidebar;
