import React from "react";
import { Heading, Text } from "react-web-skeleton";

function NotFound() {
  return (
    <>
      <div
        className={"wrapper-title flex flex-col gap-[4px] items-center mt-52"}
      >
        <Heading
          size="l"
          weight="bold"
          className={"text-blue-500 text-left sm:text-center"}
        >
          404
        </Heading>
        <Heading
          size="s"
          weight="bold"
          className={"text-neutral-900 text-left sm:text-center"}
        >
          Halaman tidak ditemukan
        </Heading>
        <Text
          size="l"
          weight="regular"
          className={"text-neutral-500 text-left sm:text-center"}
        >
          Mohon maaf, kita tidak dapat mencari halaman yang anda tuju.
        </Text>
      </div>
    </>
  );
}

export default NotFound;
