import "./App.css";
import Page from "routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/styles.css";
import * as Sentry from "@sentry/react";

function App() {
  return (
    <div>
      <ToastContainer />
      <Page />
    </div>
  );
}

export default Sentry.withProfiler(App);
