import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  ItemListDropdown,
  Icon,
  Text,
  Hint,
  List,
  ItemList,
  Loading,
} from "react-web-skeleton";

import { NavbarPanel } from "react-web-skeleton/dist/components/Navbar";
import { clearLocalStorageData } from "utils/authUtils";
import { formatDistanceToNow } from "date-fns";
import {
  getNotifications,
  readNotifications,
} from "utils/common/notifications";

function TimeAgo({ timestamp }) {
  if (!timestamp) return;
  const timeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });

  return <span>{timeAgo}</span>;
}

const Navbar = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(false);

  const name = localStorage.getItem("name") ? localStorage.getItem("name") : "";
  const roleName = localStorage.getItem("role_name")
    ? localStorage.getItem("role_name")
    : "";

  const { data, isLoading, isFetching } = useQuery(["fetchNotification"], () =>
    getNotifications()
  );

  const mutation = useMutation((updatedData) => readNotifications(), {});

  const handleSubmit = async () => {
    setCount(false);
    mutation.mutate();
  };

  useEffect(() => {
    if (data && data.length > 0) {
      if (data?.[0]?.is_read === 0) {
        setCount(true);
      }
    }
  }, [data]);

  const handleLogout = () => {
    clearLocalStorageData();
    navigate("/login");
  };

  return (
    <>
      <NavbarPanel>
        <div className="flex wrapper-menu-user gap-x-8">
          <Dropdown
            className="absolute right-28 flex-col min-w-[300px] max-w-sm scroll-m-0"
            elementAction={
              <Icon
                iconOnly
                label="bell"
                number={count && "!"}
                size="small"
                onClick={handleSubmit}
              />
            }
          >
            <div class="h-96 overflow-y-auto">
              <ItemListDropdown className="title text-center">
                <Text size="l" weight="semibold">
                  Notifikasi
                </Text>
              </ItemListDropdown>
              {isLoading ||
                (isFetching && (
                  <div
                    style={{ width: "24rem" }}
                    className={`flex items-center justify-center transition-all duration-500 ${
                      isLoading || isFetching ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    <Loading size="xl" />
                  </div>
                ))}
              {data && data?.length > 0 ? (
                data?.map((list, index) => {
                  return (
                    <ItemListDropdown
                      className={`notif-item ${
                        list.is_read ? "bg-slate-0" : "bg-slate-200"
                      }  text-left transition-all duration-500 ${
                        isLoading || isFetching ? "opacity-0" : "opacity-100"
                      }`}
                      key={index}
                    >
                      <div className="flex flex-row cursor-pointer">
                        <div
                          className="flex flex-column"
                          style={{
                            marginLeft: "8px",
                          }}
                        >
                          <Text size="m" weight="medium">
                            {list.title}
                          </Text>
                          <Text
                            size="s"
                            weight="medium"
                            className="text-[#787878] mt-1 mb-2"
                          >
                            {list.description}
                          </Text>
                          <Text size="s" weight="regular">
                            <TimeAgo timestamp={list?.created_at || ""} />
                          </Text>
                        </div>
                      </div>
                    </ItemListDropdown>
                  );
                })
              ) : (
                <div className="mx-5">
                  <Text size="m" weight="semibold">
                    Tidak ada notifikasi
                  </Text>
                </div>
              )}
            </div>
          </Dropdown>

          <Dropdown
            // className="min-w-[50px]"
            elementAction={
              <>
                <List className="text-left cursor-pointer">
                  <ItemList>
                    <Text size="m" weight="semibold">
                      {name}
                    </Text>
                    <Hint size="m" weight="regular">
                      {roleName}
                    </Hint>
                  </ItemList>
                </List>
              </>
            }
          >
            <ItemListDropdown
              className={`notif-item w-[8rem] bg-white rounded-sm shadow-md border absolute ${
                name.length < 10 ? `right-[-3.5rem]` : ``
              }`}
            >
              <List className="text-left">
                <ItemList>
                  <Text size="s" weight="semibold">
                    {name}
                  </Text>
                  <Hint size="s" weight="regular">
                    {roleName}
                  </Hint>
                </ItemList>
              </List>
              <List className="text-left my-4 cursor-pointer">
                <ItemList>
                  <Text size="s" weight="semibold">
                    Pengaturan
                  </Text>
                </ItemList>
              </List>
              <List className="text-left cursor-pointer text-red-700">
                <ItemList>
                  <Text size="s" weight="semibold" onClick={handleLogout}>
                    Keluar
                  </Text>
                </ItemList>
              </List>
            </ItemListDropdown>
          </Dropdown>
        </div>
      </NavbarPanel>
    </>
  );
};

export default Navbar;
