import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

const AddTemplate = () => {
  return (
    <div className="bg-[#F8FAFC] h-[100%] w-[100vw] pb-20">
      <Suspense fallback="loading...">
        <Outlet />
      </Suspense>
    </div>
  );
};

export default AddTemplate;
