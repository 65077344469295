import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { checkRoleId } from "utils/authUtils";

import NavbarPanel from "components/template/Navbar";
import Sidebar from "components/template/Sidebar";

import { LoadingScreen } from "components/UI/common/atoms/Loading";

const RootTemplate = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandedSidebar = () => {
    setIsExpanded((prev) => !prev);
  };

  const routes = [];

  if (checkRoleId() === "Superadmin") {
    const routesSuperadmin = [
      {
        isSub: false,
        icon: "home",
        path: "/dashboard",
        label: "Dashboard",
      },
      {
        isSub: true,
        icon: "detail",
        label: "Pendanaan",
        path: "/pendanaan",
        childs: [
          {
            path: "/pembelian",
            label: "Pembelian",
          },
          {
            path: "/bagi-hasil",
            label: "Bagi Hasil",
          },
        ],
      },
      {
        isSub: true,
        icon: "wallet",
        label: "Wallet",
        path: "/wallet",
        childs: [
          {
            path: "/top-up",
            label: "Top-up",
          },
          {
            path: "/withdraw",
            label: "Withdraw",
          },
        ],
      },
      {
        isSub: false,
        icon: "car",
        path: "/katalog",
        label: "Katalog",
      },
      {
        isSub: true,
        icon: "group",
        label: "User",
        path: "/user",
        childs: [
          {
            path: "/investor",
            label: "Investor",
          },
        ],
      },
      {
        isSub: true,
        icon: "box",
        label: "Master Data",
        path: "/master",
        childs: [
          {
            path: "/merek",
            label: "Merek",
          },
          {
            path: "/model",
            label: "Model",
          },
          {
            path: "/tipe",
            label: "Tipe",
          },
          {
            path: "/transmisi",
            label: "Transmisi",
          },
          {
            path: "/bahan-bakar",
            label: "Bahan Bakar",
          },
          {
            path: "/warna",
            label: "Warna",
          },
          {
            path: "/kategori",
            label: "Kategori",
          },
          {
            path: "/grade",
            label: "Grade",
          },
        ],
      },
      {
        isSub: false,
        icon: "slider",
        path: "/konfigurasi",
        label: "Konfigurasi",
      },
      {
        isSub: false,
        icon: "user-pin",
        path: "/admin",
        label: "Admin",
      },
    ];
    routes.push(routesSuperadmin);
  } else {
    const routesAdmin = [
      {
        isSub: false,
        icon: "home",
        path: "/dashboard",
        label: "Dashboard",
      },
      {
        isSub: true,
        icon: "detail",
        label: "Pendanaan",
        path: "/pendanaan",
        childs: [
          {
            path: "/pembelian",
            label: "Pembelian",
          },
          {
            path: "/bagi-hasil",
            label: "Bagi Hasil",
          },
        ],
      },
      {
        isSub: true,
        icon: "wallet",
        label: "Wallet",
        path: "/wallet",
        childs: [
          {
            path: "/top-up",
            label: "Top-up",
          },
          {
            path: "/withdraw",
            label: "Withdraw",
          },
        ],
      },
      {
        isSub: false,
        icon: "car",
        path: "/katalog",
        label: "Katalog",
      },
      {
        isSub: true,
        icon: "group",
        label: "User",
        path: "/user",
        childs: [
          {
            path: "/investor",
            label: "Investor",
          },
        ],
      },
    ];
    routes.push(routesAdmin);
  }

  return (
    <div className="flex h-screen ">
      <Sidebar
        routes={routes}
        logo="../Logo.png"
        isExpanded={isExpanded}
        handleExpandedSidebar={handleExpandedSidebar}
      />

      <div className="flex-1 flex flex-col overflow-hidden ">
        <NavbarPanel handleMenu={handleExpandedSidebar} />
        <Suspense fallback={<LoadingScreen />}>
          <div className="overflow-y-auto flex-1 ">
            <Outlet />
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default RootTemplate;
